import {Component, Provide, Vue,} from 'vue-property-decorator';
import common from '../../Common/Index.vue'
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

import {GetOrderProcessApi} from "@/network/modules/mine";
import {GetOrderProcessReq} from "@/interface/res/mine";


@Component({
  components: {
    common,
    ElImageViewer
  },
  computed: {
  }
})
export default class Process extends Vue {
  @Provide() id:string = ''
  @Provide() orderNo:string = ''
  @Provide() loading:boolean = false
  @Provide() cusOrderDetail:any = {}
  @Provide() cusOrderProcess:Array<any> = []
  @Provide() realSchedulingTime:string = ''

  @Provide() showBig:boolean = false;
  @Provide() showBigImgList:Array<any> = [];
  @Provide() showBigImgListIndex:number = 0;

  created(){
    const _this:any = this;
    window.scrollTo({top:0})
    if(!_this.$base.isNull(_this.$route.query.i)){
      _this.id = _this.$decryptBy(_this.$route.query.i)
    }
    _this.GetOrderProcessFn()

  }

  /**
   * @Author HS
   * @Date 2021/7/29 11:19 上午
   * @Description: 图片放大
   * @Params: { object ：item - true } [当前数据]
   * @Params: { number ：index - true } [下标]
   * @Return: null
   */
  imgClick(item:any,index:number) {
    const _this:any = this;
    _this.showBigImgList = JSON.parse(item)
    _this.showBigImgListIndex = index
    _this.showBig = true;
  }

  /**
   * @Author HS
   * @Date 2021/8/25 11:32 上午
   * @Description: 定单产品流程
   * @Params: null
   * @Return: null
  */
  async GetOrderProcessFn(){
    const _this:any = this;
    _this.loading = true;
    let params:GetOrderProcessReq = {
      orderDetailId:_this.id
    }
    const res = await GetOrderProcessApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { code, msg, data } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }

    const { cusOrderDetail, cusOrderProcess, realSchedulingTime, orderNo } = data

    _this.realSchedulingTime = realSchedulingTime
    _this.cusOrderDetail = cusOrderDetail
    _this.cusOrderProcess = cusOrderProcess
    _this.orderNo = orderNo
  }

  /**
   * @Author HS
   * @Date 2021/7/8 11:46 上午
   * @Description: 跳转到详情
   * @Params: null
   * @Return: null
   */
  goDetails(type:any){
    const _this:any = this;
    if(_this.cusOrderDetail.commoditySeries == 2)return;
    let link = ''
    if(_this.cusOrderDetail.commoditySeries == 0){
      link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path,`type=${type}&i=`+_this.cusOrderDetail.commodityId)
    }
    if(_this.cusOrderDetail.commoditySeries == 1){
      link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path,`type=${type}&i=`+_this.cusOrderDetail.commodityId)
    }
    _this.$base.goRouter(link)
  }
}
